<template>
    <div class="home">
      <div style="
            height: 20px;
            line-height: 60px;
            right: 42px;
    position: fixed;
            text-align: right;">
            <i @click="handleSelect('/usermanagement/authenticateduserlist')" class="el-icon-close"></i>
      </div>
        <el-form :inline="true" :model="formInline" class="demo-form-inline topm">
            <el-form-item :required="true" label="用户账号">
            <el-select
                v-model="formInline.phone"
                filterable
                remote
                reserve-keyword
                placeholder="请输入关键词"
                :remote-method="remoteMethod"
                :loading="loading">
                <el-option
                v-for="item in userSearch"
                :key="item.keyid"
                :label="item.id"
                :value="item.keyid">
                </el-option>
            </el-select>
            </el-form-item><br>
            <el-form-item :required="true" label="认证分类">
            <el-select v-model="formInline.category" placeholder="状态">
                <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
            </el-form-item><br>
            <el-form-item :required="true" label="有效时间">
                <el-date-picker
                    v-model="timevalue"
                    type="datetimerange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions">
                </el-date-picker>
            </el-form-item>
      <div class="pt20"></div>
            <el-form-item>
            <el-button type="primary" @click="onSubmit()">提交</el-button>
            </el-form-item>
      </el-form> 
      <div class="pt20"></div>

        
      
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import config from '../../lib/config'
import formatDate from '../../lib/dateFormat2'

export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
      formInline: {
        sdate:'',
        edate:'',
        user_id:'',
        category:-1,
        phone:'',
      },
      options:[],   
      timevalue:'',
        loading: false,
      userSearch:[],
     pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {  
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
    }
  },
  methods: {
    handleSelect(url){
        this.$router.push(url)
    },
      onSubmit(){
          if(this.timevalue){
            this.formInline.sdate = formatDate(this.timevalue[0]);
            this.formInline.edate = formatDate(this.timevalue[1]);
          }
        let _this = this;
        let userSearch = _this.userSearch;
        for(let i in userSearch){
            if(userSearch[i].keyid == _this.formInline.phone){
                _this.formInline.user_id = userSearch[i].keyid;
                _this.formInline.phone = userSearch[i].id;
            }
        }
        axios.put(config.authentication,this.formInline)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                message: response.data.message,
                type: 'success'
              });
              _this.goNav('/usermanagement/authenticateduserlist');
            }else if(response.data.code == 422){
                _this.$message.error('请填写必填项');
            }else{
                _this.$message.error(response.data.message);
            }
                
          })
        .catch(function (error) {
          console.log(error);
        });
      },
       remoteMethod(query) {
        let _this = this;
        if (query !== '') {
          this.loading = true;
          axios.get(config.userSearch,{params:{query:query,page:1}})
        .then(function (response) {
             if(response.data.code == 200){
              _this.loading = false;
            _this.userSearch = response.data.data.user
            }
          })
        .catch(function (error) {
          console.log(error);
        });
            
        } else {
          _this.userSearch = [];
        }
      },
      goNav(url){
        this.$router.push(url)
      },
    ininoptions(){
        let _this = this;
        axios.get(config.selectauthentication,{})
        .then(function (response) {
            if(response.data.code == 200){
              _this.options = response.data.data
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  created(){
      this.ininoptions();
  }
}
</script>
<style scoped>
.left{
    width: 335px;
}
.el-icon-close:before{
  font-size: 24px;
}
.topm{
  margin-top: 20px;
}
</style>